.web-footer-container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 0;
  text-align: center;
  height: 100px;
  // display: flex;
  // justify-content: center;
  // position: fixed;
  // bottom: 0;
  // z-index: 100;
  // margin-left: 46vw;

  p {
    color: #acacac;
  }
  //   &.signin {
  //     bottom: 48vh;
  //   }
}
