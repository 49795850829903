@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

.replacefse-modal {
  .modal {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 14;
  }

  .wrapper-modal {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: -17px;

    .modal-main {
      display: flex;
      flex-direction: column;

      padding: 12px;
      border-radius: 8px;

      max-height: calc(100vh - 0px);
      overflow-y: auto;
      scrollbar-width: none;

      min-width: auto;
      position: fixed;

      background: white;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 550px;

      .modal-header {
        padding: 12px;
        display: flex;
        justify-content: space-between;

        .headline {
          .main-headline {
            font-family: Roboto;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #434364;
          }

          .sub-headline {
            font-family: Roboto;
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
            color: #707070;
          }
        }

        .close-button-corner {
          position: absolute;
          top: 0px;
          right: 0px;
          cursor: pointer;

          .corner {
            position: absolute;
            top: 0;
            right: 0px;
            cursor: pointer;
          }
          .cross {
            cursor: pointer;
            top: 24px;
            right: 22px;
            position: relative;
          }
        }
      }

      .replace-modal-body {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;

        border-bottom: 1px solid #f2f2f2;

        @include for-phone-only {
          justify-content: center;
        }

        @include for-tablet-portrait-up {
          justify-content: center;
        }

        @include for-tablet-landscape-up {
          justify-content: center;
        }

        @include for-desktop-up {
          justify-content: center;
        }

        @include for-big-desktop-up {
          justify-content: center;
        }

        .basic {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;

          @include for-phone-only {
            justify-content: center;
          }

          @include for-tablet-portrait-up {
            justify-content: center;
          }

          @include for-tablet-landscape-up {
            justify-content: center;
          }

          @include for-desktop-up {
            justify-content: space-between;
          }

          @include for-big-desktop-up {
            justify-content: space-between;
          }

          .image {
            position: relative;
            height: 128px;
            width: 128px;
            overflow: hidden;

            margin: 12px 12px 30px 12px;

            img {
              height: 128px;
              width: 128px;

              border-radius: 50%;
            }
          }
        }
      }

      .group {
        position: relative;
        margin: 25px 0;

        input {
          background: none;
          display: block;
          // padding: 16px 16px 16px 16px;
          padding-left: 16px;

          border: none;
          border-radius: 4px;
          border: 1px solid #b7b7b7;

          color: #252525;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;

          width: 500px;

          &:focus {
            outline: none;
            box-shadow: none !important;
          }

          &:focus ~ label,
          &:valid ~ label {
            top: -10px;
            left: 15px;
            padding: 0px;
            background: white;

            font-family: Roboto;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #b7b7b7;
          }

          &:focus ~ .bar:before {
            width: 100%;
          }
        }

        label {
          position: absolute;
          pointer-events: none;
          left: 5px;
          top: 0px;
          transition: 300ms ease all;
          padding: 16px;

          color: #b7b7b7;
          font-size: 16px;
          line-height: 20px;
          font-family: Roboto;
          font-weight: 400;
        }
      }

      form {
        padding: 0 12px;
      }

      .footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;

        @include for-phone-only {
          justify-content: center;
        }

        @include for-tablet-portrait-up {
          justify-content: center;
        }

        @include for-tablet-landscape-up {
          justify-content: center;
        }

        @include for-desktop-up {
          justify-content: flex-end;
        }

        @include for-big-desktop-up {
          justify-content: flex-end;
        }
        .alert-box {
          max-width: 182px;

          p {
            font-size: 12px;
            color: #ff0000;
          }
        }
        .success-box {
          max-width: 197px;

          p {
            font-size: 12px;
            color: #13e281;
          }
        }
        .css-13o4zwn {
          position: absolute;
          left: 17px;
          top: 25px;
        }

        .button--save {
          cursor: pointer;
          background: #e2136e;
          height: 40px;
          width: 140px;

          border: none;
          outline: none;
          border-radius: 4px;

          font-family: Roboto;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: white;

          margin: 12px;
        }

        .button--cancel {
          cursor: pointer;
          background: white;
          height: 40px;
          width: 140px;

          border: none;
          outline: none;
          border: 1px solid #e2136e;
          border-radius: 4px;

          font-family: Roboto;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #e2136e;

          margin: 12px;
        }

        .button--replace {
          display: flex;
          justify-content: center;
          align-items: center;
          background: white;
          min-width: 161px;
          min-height: 41px;

          padding: 0px;

          margin: 30px 12px 18px 12px;

          border: 1px solid #e2136e;
          border-radius: 4px;

          font-family: Roboto;
          font-weight: 500;
          line-height: 20px;
          font-size: 14px;
          color: #e2136e;

          img {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}
