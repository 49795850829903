@import "../../breakpoints.scss";

.user-management {
  padding: 32px;
  max-width: 1366px;
  margin: 0 auto;

  .title-buttons-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    h2 {
      font-size: 24px;
    }

    .title {
      min-width: 168px;

      h2 {
        font-weight: 500;
        margin: 1.373rem 0 1.424rem 0;
      }
    }
    .buttons-group {
      display: flex;
      flex-grow: 1;
      flex-wrap: nowrap;
      margin-left: 25px;
      @include media("<=499px") {
        margin-left: 0;
      }

      .im-btn {
        background-color: #ffffff;
        border: 1px solid #f2f2f2;
        box-shadow: none;
        width: 112px;
        height: 44px;

        &.fse-btn {
          border-radius: 4px 0 0 4px;
        }
        &.mdo-btn {
          border-radius: 0 4px 4px 0;
        }
        &.inactive {
          color: #434364;
        }
        &.active {
          color: #e2136e;
        }

        i {
          margin-right: 0px;
          font-size: 24px;
        }
      }
    }
  }

  .divider {
    background-color: #f2f2f2;
    margin: 15px auto !important;
  }

  .button-filter-search {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .input-field {
      &.col {
        border: 1px solid #f2f2f2 !important;
        height: 46px !important;

        .select-wrapper {
          input[type="text"] {
            height: 40px;
          }
        }
      }
    }
    .grouped-filter-search {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;

      @include media("<=802px") {
        justify-content: flex-start;
      }
      @include media("<=644px") {
        margin-top: 15px;
        flex-wrap: wrap;

        .dd-list-container {
          z-index: 1;
        }
        .searchbox {
          width: 266px !important;
        }
      }

      .filter-by-agency {
        width: 265px;
        margin-right: 20px;
      }
      .searchbox {
        width: 287px;
      }
    }
  }
  .pagination-container {
    margin-bottom: 15px;
  }
}
