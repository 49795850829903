@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");

@mixin styling() {
  background-color: white;
  border-color: #b7b7b7;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-family: "Roboto", sans-serif !important;
  // box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.replacefse-select-fse {
  margin: 25px 0;

  .dd-wrapper {
    display: flex;
    min-height: 38px;
    flex-wrap: wrap;
    // width: 517px;

    .dd-header {
      @include styling();
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      width: 100%;
      padding: 0 20px;
      align-items: center;
      height: 49px;
      &__title--grey {
        font-weight: 400;
        color: #c4bcbc;
        font-size: 16px;
      }
      &__title--black {
        font-weight: 400;
        color: #252525;
        font-size: 16px;
        margin: 0px;
      }

      .dd-header__action--openclose {
        .close {
          transform: rotate(180deg);
        }
      }
    }

    .dd-list-container {
      overflow: hidden;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08),
        0 12px 24px 0 rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      position: relative;
      margin-top: 20px;
      width: 100%;
      max-width: 520px;
      background-color: #fff;
      z-index: 1;

      .dd-action--clear {
        cursor: pointer;
        text-align: center;

        p {
          font-family: "Roboto", sans-serif;
          color: #e2136e;
        }
      }

      .dd-searchbox {
        display: flex;
        padding: 15px 30px 15px 28px;

        .search-icon {
          position: absolute;
          top: 5px;
          left: 6px;
        }
        .search-lable {
          position: relative;
        }
        #search_input {
          font-family: "Roboto", sans-serif;
          width: 100%;
          padding-left: 40px;
          height: 30px;
          box-shadow: none !important;
          border-radius: 4px;
          border: 1px solid #f2f2f2;
          &:focus,
          &:hover {
            box-shadow: none;
          }
        }
      }

      .dd-list-content {
        background-color: #fff;
        padding: 0;
        margin: 0;
        width: 100%;
        max-height: 250px;
        overflow-y: scroll;

        li {
          list-style-type: none;

          // &:first-of-type {
          //   > button {
          //     padding-top: 22px;
          //   }
          // }

          //   &:last-of-type > button {
          //     border-bottom-left-radius: 4px;
          //     border-bottom-right-radius: 4px;
          //   }

          button {
            display: flex;
            justify-content: space-between;
            background-color: white;
            font-size: 16px;

            border: 0;
            width: 100%;
            color: #434364;
            align-items: center;

            &.padding-m {
              padding: 6px 20px 6px 20px;
            }
            &.padding-s {
              padding: 15px 28px 15px 30px;
            }

            .checkbox {
              &.MuiCheckbox-root {
                color: #e2136e;
              }
            }
            .name {
              text-align: left;
              flex-grow: 1;
              font-family: "Roboto", sans-serif;
            }
            .l-padding {
              padding-left: 8px;
            }

            &:hover,
            &:focus {
              cursor: pointer;
              font-weight: 400;
              background-color: #fff8fb;
            }
          }
        }
      }
    }
  }
}
