.mdo-row {
  min-height: 105px;

  .worker-avatar-column {
    padding-left: 25px;
    .avatar-chip {
      display: flex;
      align-items: center;
      .worker-image {
        height: 40px;
        width: 40px;
        border-radius: 100px;
      }
      p {
        padding-left: 16px;
      }
    }
  }

  .mdo-phone {
  }

  .spots {
  }
}
