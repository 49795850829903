@import "../../breakpoints.scss";

//fixes
.dd-header {
  outline: none;
}
.dd-header__title--black {
  margin: 0px;
}

.main-container {
  .header-bg {
    background-color: #fff;
    .header-container {
      padding: 32px;
      display: flex;
      align-items: center;
      max-width: 1366px;
      margin: 0 auto;
      @include media("<=desktop") {
        flex-wrap: wrap;
      }

      h2 {
        font-size: 24px;
      }

      h3 {
        font-size: 16px;
      }

      .back-btn {
        display: flex;

        a {
          color: #e2136e;
          outline: none;

          .back-icn {
            padding: 0 10px 0 10px;
            border: 1px solid #e2136e;
            border-radius: 4px;
            font-size: 28px;
          }
        }
      }

      .fse-details {
        display: flex;
        margin-left: 40px;
        flex-grow: 1;

        @include media("<=desktop") {
          flex-wrap: wrap;
        }
        @include media("<543px") {
          justify-content: center;
          margin-left: 5px;
          margin-top: 25px;
        }

        .img-container {
          @include media("<543px") {
            flex-grow: 1;
            width: 100%;
            display: flex;
            justify-content: center;
          }
          .user-image {
            width: 152px;
            height: 152px;
            border-radius: 100%;
            border: 1px solid #e2136d2a;
            @include media("<=desktop") {
              width: 132px;
              height: 132px;
            }
            @include media("<580px") {
              width: 95px;
              height: 95px;
            }
          }
        }

        .name-phone {
          display: flex;
          flex-direction: column;
          padding-right: 50px;
          margin-left: 25px;

          .name-tag {
            display: flex;
            align-items: center;
            margin-bottom: -10px;

            @include media("<543px") {
              flex-direction: column;
              margin-bottom: 10px;
            }

            .name {
              font-weight: 500;
              color: #434364;
            }

            .tag {
              background-color: #f6e1ba;
              padding-left: 4px;
              padding-right: 4px;
              margin-left: 20px;
              margin-bottom: 4px;
              border-radius: 3px;
            }
          }

          .phone-copy {
            margin-top: -15px;
            display: flex;
            align-items: center;

            @include media("<543px") {
              justify-content: center;
            }

            h3 {
              margin-right: 25px;
              color: #434364;
              font-weight: 500;
            }

            a {
              color: #e2136e;
              margin-top: 10px;
            }
          }
        }

        .agency {
          // padding-right: 100px;
          margin-right: 50px;
          display: flex;
          align-items: center;
          @include media("<=tablet") {
            margin-left: 146px;
            margin-top: -20px;
          }
          @include media(">768px", "<=810px") {
            margin-left: 150px;
            margin-top: -20px;
          }
          @include media("<580px") {
            margin-left: 115px;
          }
          @include media("<543px") {
            margin-left: 0px;
            margin-top: 15px;
            flex-grow: 1;
            width: 100%;
            display: flex;
            justify-content: center;
          }

          .agency-icon {
            color: #b7b7b7;
            margin-right: 20px;
          }

          .agency-name-label {
            .agency-label {
              font-size: 12px;
              color: #707070;
              margin-bottom: -25px;
            }

            // .agency-name {
            // }
          }
        }
      }

      .im-primary {
        text-transform: none;
        color: #fff;
        background-color: #e2136e;
        font-size: 16px;
        font-weight: 500;
        border-radius: 4px;
        box-shadow: none;
        margin: 2px;
        margin-bottom: 12px;
        min-width: 156px;
        @include media("<=desktop") {
          margin-bottom: 22px;
        }
        @include media("<580px") {
          margin-bottom: 5px;
        }

        &.im-primary:hover {
          background-color: #ca1966;
        }
      }

      .im-secondary {
        text-transform: none;
        color: #e2136e;
        background-color: #fff;
        font-size: 16px;
        font-weight: 500;
        border-radius: 4px;
        box-shadow: none;
        margin: 2px;
        border: 1px solid #e2136e;
        min-width: 165px;
        @include media("<580px") {
          min-width: 250px;
        }

        &.im-secondary:hover {
          background-color: rgb(248, 242, 242);
        }
      }
      .btn-all {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: center;

        @include media("<580px") {
          flex-direction: column;
        }
        .btn-group {
          display: flex;
          flex-direction: column;
          flex-basis: 165px;
          margin-right: 40px;
          @include media("<=desktop") {
            margin-top: 20px;
            margin-right: 0px;
            flex-direction: row;
          }
          @include media("<580px") {
            flex-direction: column;
            margin-bottom: -79px;
          }
        }

        .replace-btn {
          display: flex;
          justify-content: flex-end;
          min-width: 167px;
        }
      }
    }
  }

  .mdo-display {
    max-width: 1366px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    h2 {
      font-size: 20px;
      margin-right: 15px;
      font-weight: 500;
    }

    .mdo-count {
      background-color: #fff;
      color: #434364;
      font-size: 14px;
      padding-left: 5px;
      padding-right: 5px;
      margin-top: 28px;
      border-radius: 4px;
    }
  }

  .divider {
    max-width: 1366px;
    margin: 8px auto;
  }

  .content {
    max-width: 1366px;
    margin: 0 auto;

    .worker-table {
      border: 1px solid #e9eaee;
      border-radius: 8px;

      @include media("<desktop") {
        margin: 15px;
        overflow-x: scroll;
      }

      table {
        background-color: white;
        border-radius: 8px;

        thead {
          tr {
            th {
              padding-left: 30px;
            }

            .name {
              width: 350px;
            }

            // .phone {
            //   //   width: 150px;
            // }

            // .agency {
            //   //   width: 500px;
            // }
          }
        }

        tbody {
          tr {
            border-bottom: 1px dashed #e9eaee;

            td {
              padding-left: 30px;
            }
          }

          tr:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
