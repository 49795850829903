#root {
  position: relative;
  min-height: 100vh;
}

body {
  background: #FAFAFA;
  font-family: 'Roboto';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.spinner-red-only {
  border-color: rgb(255, 236, 249);
}