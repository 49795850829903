// //Global styles for buttons
// .im-primary {
//   text-transform: none;
//   color: #fff;
//   background-color: #e2136e;
//   font-size: 16px;
//   border-radius: 4px;
//   box-shadow: none;
//   margin: 2px;

//   &.im-primary:hover {
//     background-color: #ca1966;
//   }
// }
// .im-secondary {
//   text-transform: none;
//   color: #e2136e;
//   background-color: #fff;
//   font-size: 16px;
//   border-radius: 4px;
//   box-shadow: none;
//   margin: 2px;
//   border: 1px solid #e2136e;

//   &.im-secondary:hover {
//     background-color: rgb(248, 242, 242);
//   }
// }

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
body {
  font-family: "Roboto", sans-serif;
}
