@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
.viewlogfse-modal {
  .modal {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 3;
  }

  .wrapper-modal {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: -17px;

    .modal-main {
      display: flex;
      flex-direction: column;

      padding: 12px;
      border-radius: 8px;

      max-height: calc(100vh - 0px);
      overflow-y: auto;
      scrollbar-width: none;

      min-width: auto;
      position: fixed;

      background: white;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .modal-header {
        padding: 12px;
        display: flex;
        justify-content: space-between;

        .headline {
          .main-headline {
            font-family: Roboto;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #434364;
          }

          .sub-headline {
            font-family: Roboto;
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
            color: #707070;
          }
        }

        .close-button-corner {
          position: absolute;
          top: 0px;
          right: 0px;
          cursor: pointer;

          .corner {
            position: absolute;
            top: 0;
            right: 0px;
            cursor: pointer;
          }
          .cross {
            cursor: pointer;
            top: 24px;
            right: 22px;
            position: relative;
          }
        }
      }

      .modal-body {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: space-between;
        // overflow-y: auto !important;

        border-bottom: none;

        @include for-phone-only {
          justify-content: center;
        }

        @include for-tablet-portrait-up {
          justify-content: center;
        }

        @include for-tablet-landscape-up {
          justify-content: center;
        }

        @include for-desktop-up {
          justify-content: space-between;
        }

        @include for-big-desktop-up {
          justify-content: space-between;
        }

        .basic {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;

          @include for-phone-only {
            justify-content: center;
          }

          @include for-tablet-portrait-up {
            justify-content: center;
          }

          @include for-tablet-landscape-up {
            justify-content: center;
          }

          @include for-desktop-up {
            justify-content: space-between;
          }

          @include for-big-desktop-up {
            justify-content: space-between;
          }

          .log-table {
            table {
              background-color: white;
              border-radius: 8px;
              display: block;
              height: auto;
              max-height: 500px;
              overflow-y: scroll;
              thead {
                tr {
                  border-bottom: 1px dashed #e9eaee;
                  th {
                    padding-left: 30px;
                    font-weight: 500;
                    font-size: 14px;
                    color: #252525;
                  }
                  .date {
                    width: 170px;
                  }
                  .action {
                    width: 435px;
                  }
                  .changes-by {
                    width: 190px;
                  }
                }
              }
              tbody {
                tr {
                  border-bottom: 1px dashed #e9eaee;
                  td {
                    padding: 16px 30px;
                    font-size: 14px;
                    font-weight: 400;
                  }

                  .r-date {
                    color: #707070;
                  }
                  .r-action {
                    color: #252525;
                  }
                  .r-changes {
                    color: #707070;
                  }
                }
                tr:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}
