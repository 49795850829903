@import "./screen_size.scss";

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

.custom-nav {
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 80px;
  // margin: 0px !important;

  box-shadow: none !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08) !important;

  z-index: 10;

  button {
    background: none !important;
    border: none !important;

    @media #{$desktop} {
      display: none;
    }
  }

  .logo {
    height: 80px;
    width: 250px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .custom-nav-wrapper {
    width: 100%;

    .navigation-desktop {
      display: none;

      @media #{$desktop} {
        display: flex;
        justify-content: center;
      }

      .navigation-links {
        display: flex;
        justify-content: center;
        width: 100%;

        li {
          display: flex;
          align-items: center;

          a {
            .link-description {
              display: flex;
              align-items: center;
              font-family: Roboto;
              color: #434364 !important;
              font-style: normal !important;
              font-weight: normal !important;
              font-size: 16px !important;
              line-height: 20px;
            }

            &:hover {
              background: #fafafa !important;
            }
          }

          .active-menu {
            .link-description {
              display: flex;
              align-items: center;
              font-family: Roboto;
              color: #e2136e !important;
              font-style: normal !important;
              font-weight: normal !important;
              font-size: 16px !important;
              line-height: 20px;
            }
          }
        }
      }

      .operation-links {
        width: 200px;
        display: flex;
        justify-content: right;

        li {
          a {
            color: rgb(102, 102, 102) !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 15px !important;
          }
        }
      }
    }

    .navigation-mobile {
      top: 70px;
      right: 20px;
      position: fixed;
      background: white;
      width: 250px;

      padding: 20px;
      box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.08) !important;
      border-radius: 4px;

      .navigation-links {
        display: flex;
        justify-content: center;
        flex-direction: column;

        border-bottom: 1px solid rgb(238, 238, 238);

        li {
          a {
            color: rgb(102, 102, 102) !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 15px !important;
          }

          .active-menu {
            .link-description {
              display: flex;
              align-items: center;
              font-family: Roboto;
              color: #e2136e !important;
              font-style: normal !important;
              font-weight: normal !important;
              font-size: 16px !important;
              line-height: 20px;
            }
          }

          &:hover {
            background: #fafafa !important;
          }
        }
      }

      .operation-links {
        display: flex;
        flex-direction: column;

        li {
          a {
            color: rgb(102, 102, 102) !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 15px !important;
          }
        }
      }
    }

    .navigation-dropdown-button {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      @media #{$desktop} {
        display: none;
      }

      li {
        color: rgb(102, 102, 102) !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 15px !important;
      }
    }
  }
}

.sidenav-overlay {
  display: none !important;

  @media #{$desktop} {
    display: none !important;
  }
}

.sidenav {
  background: white !important;

  height: 100vh;
  width: 248px;

  position: fixed;
  z-index: 8;

  .menu {
    padding: 88px 8px 0px 0px;
    width: 240px;

    a {
      .link-description {
        height: 48px;
        padding: 12px 16px 12px 24px !important;

        i {
          height: 24px;
          width: 24px;

          color: rgb(80, 80, 80) !important;
        }

        span {
          font-family: Roboto;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: rgb(85, 85, 85);
        }
      }
    }

    .active-menu {
      .link-description {
        background-color: #fff8fb;
        border-radius: 0 100px 100px 0;

        i {
          color: #e2136e !important;
        }

        span {
          color: #e2136e;
        }
      }
    }
  }
}

.map-region-name {
  fill: rgb(100, 3, 60);
  font-weight: 700;
}

/*SIGNIN*/
.signIn {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100vh;

  padding: 100px 0px 0px 0px !important;

  form {
    margin: 0px 10px !important;
    text-align: center;

    @media #{$desktop} {
      padding: 40px 48px;
    }

    padding: 40px 48px;

    border-radius: 8px;
    box-shadow: 0px 0px 3px 1px #fafafa !important;

    .input-field {
      box-sizing: border-box;
      height: 49px !important;
      background-color: #ffffff !important;

      border: 1px solid #e2136e !important;
      border-radius: 4px !important;

      padding-left: 10px !important;

      input {
        border: none !important;

        &:focus {
          box-shadow: none !important;
          border: none !important;
        }
      }

      i {
        height: 24px !important;
        width: 24px !important;

        color: #a4a4a4;
      }

      .active {
        color: #e2136e !important;
      }
    }

    button {
      width: 100%;
      height: 40px;

      border-radius: 4px;

      color: #ffffff;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }
  }
}

/*CALL PAGINATION*/
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  padding: 10px;

  .limit {
    color: #808080;
    font-family: Roboto;
    font-size: 12px;
    line-height: 14px;

    .amount-picker {
      width: 42px;
      height: 30px;
      border-bottom: none;
      border: 1px solid #eaeaea;
      background-color: #ffffff;
      border-radius: 4px;
      padding: 0 0 0 8px;
      margin: 0px 8px 0px 8px;
      font-size: 12px;
    }
  }

  .current {
    color: #808080;
    font-family: Roboto;
    font-size: 12px;
    line-height: 14px;
  }

  .controller {
    ul {
      margin: 0px !important;
      padding: 0px !important;

      max-width: 88px;
      display: flex;
      justify-content: space-between;

      li {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 24px;
        width: 24px;

        background-color: #ffffff;

        text-align: center;
        color: #808080;
        font-family: Roboto;
        font-size: 12px;
        line-height: 14px;

        margin: 0px !important;
        margin-left: 8px !important;
        padding: 0px !important;

        box-sizing: border-box;
        border: 1px solid #eaeaea;
        border-radius: 4px;

        i {
          color: #3d3d3d;
        }
      }
    }
  }
}

/*CALENDAR*/
.date-controller {
  display: flex;
  z-index: 1;

  .today {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 40px !important;

    background: white;

    // margin: 8px 0px !important;
    padding: 10px !important;

    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    border-left: 1px solid #f2f2f2;
    border-radius: 4px 0px 0px 4px;

    cursor: pointer;

    .heading {
      padding: 10px;
    }

    span {
      display: inline;

      color: #e2136e;
      font-family: Roboto;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .yesterday {
    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0.2;

    margin: 8px 0px 8px 0px !important;
    border-top: 1px solid #e2136e;
    border-bottom: 1px solid #e2136e;
    border-left: 1px solid #e2136e;

    .heading {
      padding: 10px;
    }

    span {
      display: inline;
    }
  }

  .calendar-container {
    // margin: 8px 8px 8px 0px !important;
    border: 1px solid #f2f2f2;
    display: flex;
    background: white;
    border-radius: 0px 4px 4px 0px;
    height: 40px;

    .heading {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 10px;
    }

    span {
      display: inline;
      overflow: hidden;
      white-space: nowrap;
    }

    i {
      color: #e2136e;
    }
  }
}

/*REACT-CALENDAR-OVERWRITE*/
.react-daterange-picker {
  left: 5px;

  // animation-name: fade-in;
  // animation-fill-mode: both;
  // animation-duration: .5s;

  span {
    .react-daterange-picker__calendar {
      font-family: Roboto !important;

      .react-calendar__navigation {
        height: 100%;
        background: rgb(255, 255, 255);

        font-family: Roboto !important;
        font-size: 16px !important;
        line-height: 24px !important;

        border-radius: 8px 8px 0px 0px !important;

        margin-bottom: 0px !important;
        padding: 24px;

        button {
          color: rgb(0, 0, 0);
        }

        .react-calendar__navigation__label {
          color: rgb(114, 114, 114);
        }
      }

      .react-calendar__viewContainer {
        .react-calendar__month-view {
          padding: 17px;
          margin-top: 0px;

          div {
            div {
              .react-calendar__month-view__weekdays {
                .react-calendar__month-view__weekdays__weekday {
                  font-family: Roboto;
                  font-weight: normal !important;
                  font-size: 14px;

                  abbr {
                    text-decoration: none !important;
                  }
                }
              }

              .react-calendar__month-view__days {
                .react-calendar__month-view__day {
                  font-family: Roboto regular;
                  font-size: 14px;

                  abbr {
                    text-decoration: none !important;
                  }
                }

                .react-calendar__tile--hasActive {
                  background: rgba(226, 19, 109, 0.5) !important;
                  color: rgba(226, 19, 109, 1) !important;
                }

                .react-calendar__tile--rangeStart {
                  background: rgba(226, 19, 109, 0.3) !important;
                  color: rgba(226, 19, 109, 1) !important;
                }

                .react-calendar__tile--rangeEnd {
                  background: rgba(226, 19, 109, 0.3) !important;
                  color: rgba(226, 19, 109, 1) !important;
                }

                .react-calendar__tile--active {
                  background: rgba(226, 19, 109, 0.2);
                  color: rgba(226, 19, 109, 1) !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .react-daterange-picker__wrapper {
    border: none !important;

    .react-daterange-picker__inputGroup {
      display: none;
    }

    .react-daterange-picker__clear-button {
      display: none;
    }

    .react-daterange-picker__range-divider {
      display: none;
    }

    button {
      svg {
        stroke: #e2136e !important;
      }

      padding: 0px !important;

      &:focus {
        background-color: white !important;
      }
    }
  }

  .react-calendar {
    padding: 0px 16px;

    width: 100vw-10 !important;
    height: auto !important;

    position: fixed;
    top: 215px !important;
    left: 50% !important;
    transform: translate(-50%, 0px) !important;
    z-index: 1;

    border: none !important;
    border-radius: 8px !important;

    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08) !important;

    animation-name: fade-in;
    animation-fill-mode: both;
    animation-duration: 0.5s;

    @media #{$desktop} {
      position: absolute;
      top: 15px !important;
      left: 90% !important;
      transform: translate(-90%, 0px) !important;

      animation-name: fade-in;
      animation-fill-mode: both;
      animation-duration: 0.5s;
    }
  }
}

/*REACT-CALENDAR-DATEPICKER-OVERWRITE*/
.react-date-picker {
  left: 5px;

  span {
    .react-date-picker__calendar {
      font-family: Roboto !important;

      .react-calendar__navigation {
        height: 100%;
        background: rgb(255, 255, 255);

        margin-bottom: 0px !important;
        padding: 24px;

        font-family: Roboto !important;
        font-size: 16px !important;
        line-height: 24px !important;

        border-radius: 8px 8px 0px 0px !important;

        button {
          color: rgb(0, 0, 0);
        }

        .react-calendar__navigation__label {
          color: rgb(114, 114, 114);
        }
      }

      .react-calendar__viewContainer {
        .react-calendar__month-view {
          padding: 17px;
          margin-top: 0px;

          div {
            div {
              .react-calendar__month-view__weekdays {
                .react-calendar__month-view__weekdays__weekday {
                  font-family: Roboto;
                  font-weight: normal !important;

                  abbr {
                    text-decoration: none !important;
                  }
                }
              }

              .react-calendar__month-view__days {
                .react-calendar__month-view__day {
                  font-family: Roboto regular;
                  font-size: 14px;

                  abbr {
                    text-decoration: none !important;
                  }
                }

                .react-calendar__tile--hasActive {
                  background: rgba(226, 19, 109, 0.5) !important;
                  color: rgba(226, 19, 109, 1) !important;
                }

                .react-calendar__tile--rangeStart {
                  background: rgba(226, 19, 109, 0.3) !important;
                  color: rgba(226, 19, 109, 1) !important;
                }

                .react-calendar__tile--rangeEnd {
                  background: rgba(226, 19, 109, 0.3) !important;
                  color: rgba(226, 19, 109, 1) !important;
                }

                .react-calendar__tile--active {
                  background: rgba(226, 19, 109, 0.2);
                  color: rgba(226, 19, 109, 1) !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .react-date-picker__wrapper {
    border: none !important;

    .react-date-picker__inputGroup {
      display: none;
    }

    .react-date-picker__clear-button {
      display: none;
    }

    .react-date-picker__range-divider {
      display: none;
    }

    button {
      svg {
        stroke: #e2136e !important;
      }

      padding: 0px !important;

      &:focus {
        background-color: white !important;
      }
    }
  }

  .react-calendar {
    width: 100vw-10 !important;
    height: auto !important;

    padding: 0px 16px;

    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08) !important;

    position: fixed;
    top: 215px !important;
    left: 50% !important;
    transform: translate(-50%, 0px) !important;
    z-index: 1;

    border: none !important;
    border-radius: 8px !important;
    border-radius: 8px;

    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08) !important;

    @media #{$desktop} {
      position: absolute;

      top: 15px !important;
      left: 90% !important;
      transform: translate(-90%, 0px) !important;
    }
  }
}

.main-container {
  padding: 80px 0px 90px 0px;

  @media #{$desktop} {
    max-width: 100% !important;
    width: 100% !important;
    padding: 80px 0px 90px 0px;
  }
}

.ReactModal__Overlay {
  z-index: 11;
}

.leaflet-container {
  z-index: 9;
}